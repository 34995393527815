import("../../../../modules/system/assets/js/framework-bundle");

import {
    myFunction,
    MyJsModule,
    otherFunction,
    sub,
} from "./modules/myJsModule";

import { CookieManager } from "../../../../plugins/seba/ockekse/assets/modules/cookieManager";

document.addEventListener("DOMContentLoaded", function () {
    const cookieManager = new CookieManager();
    cookieManager.init();
    observers();

    let windowHeight = window.innerHeight;
    let navHeight = document.querySelector("nav").offsetHeight;

    let banners = document.querySelectorAll(".banner");

    window.addEventListener("resize", () => {
        let banners = document.querySelectorAll(".banner");

        banners.forEach((banner) => {
            banner.style.height = windowHeight - navHeight + "px";

            let bannerImage = banner.querySelector("img");
            let overlay = banner.querySelector(".white-overlay");
            let bannerImageHeight = bannerImage.offsetHeight;
            let bannerImageWidth = bannerImage.offsetWidth;
            let grayTriangle = banner.querySelector(".gray-triangle");
            let blueTriangle = banner.querySelector(".blue-triangle");

            overlay.style.borderRightWidth = bannerImageWidth / 2 + "px";
            overlay.style.borderTopWidth = bannerImageHeight + "px";

            grayTriangle.style.borderRightWidth = bannerImageWidth / 4 + "px";
            grayTriangle.style.borderLeftWidth = bannerImageWidth / 4 + "px";
            grayTriangle.style.borderTopWidth = bannerImageHeight / 2 + "px";

            blueTriangle.style.borderRightWidth =
                (bannerImageWidth / 8) * 1.5 + "px";
            blueTriangle.style.borderLeftWidth =
                (bannerImageWidth / 8) * 1.5 + "px";
            blueTriangle.style.borderTopWidth =
                (bannerImageHeight / 4) * 1.5 + "px";
            blueTriangle.style.left = bannerImageWidth / 8 + "px";
        });
    });

    banners.forEach((banner) => {
        banner.style.height = windowHeight - navHeight + "px";

        let bannerImage = banner.querySelector("img");
        let overlay = banner.querySelector(".white-overlay");
        let bannerImageHeight = bannerImage.offsetHeight;
        let bannerImageWidth = bannerImage.offsetWidth;
        let grayTriangle = banner.querySelector(".gray-triangle");
        let blueTriangle = banner.querySelector(".blue-triangle");

        overlay.style.borderRightWidth = bannerImageWidth / 2 + "px";
        overlay.style.borderTopWidth = bannerImageHeight + "px";

        grayTriangle.style.borderRightWidth = bannerImageWidth / 4 + "px";
        grayTriangle.style.borderLeftWidth = bannerImageWidth / 4 + "px";
        grayTriangle.style.borderTopWidth = bannerImageHeight / 2 + "px";

        blueTriangle.style.borderRightWidth =
            (bannerImageWidth / 8) * 1.5 + "px";
        blueTriangle.style.borderLeftWidth =
            (bannerImageWidth / 8) * 1.5 + "px";
        blueTriangle.style.borderTopWidth =
            (bannerImageHeight / 4) * 1.5 + "px";
        blueTriangle.style.left = bannerImageWidth / 8 + "px";

        document.querySelector(".contact-icon-wrapper").onmousemove = () => {
            document
                .querySelector(".contact-icon-wrapper")
                .classList.add("on-mouseover");
            document
                .querySelector(".contact-content-inner")
                .classList.add("on-mouseover");
        };

        document.querySelector(".contact-icon-wrapper").onmouseout = () => {
            document
                .querySelector(".contact-icon-wrapper")
                .classList.remove("on-mouseover");
            document
                .querySelector(".contact-content-inner")
                .classList.remove("on-mouseover");
        };
    });




    function observers(){


    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
          // If the element is visible
          if (entry.isIntersecting) {
              let valueDisplays = document.querySelectorAll(".counter-num");
              let interval = 4000;
              valueDisplays.forEach((valueDisplay) => {
                  let startValue = 0;
                  let endValue = parseInt(
                      valueDisplay.getAttribute("data-val")
                  );
                  let duration = Math.floor(interval / endValue);
                  let counter = setInterval(function () {
                      startValue += 1;
                      valueDisplay.textContent = startValue;
                      if (startValue == endValue) {
                          clearInterval(counter);
                      }
                  }, duration);
              });
          }
      });
  });
  
    const bannerObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            entry.target.classList.add("banner-image-show");
        });
    });

    const animationObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                console.log(entry);
                entry.target.classList.add("fly-in-show");
            }
        });
    });

    if (document.querySelector(".counter-item-wrapper")) {
        observer.observe(document.querySelector(".counter-item-wrapper"));
    }

    const bannerImages = document.querySelectorAll(".banner-image");
    bannerImages.forEach((el) => bannerObserver.observe(el));

    const flyInElements = document.querySelectorAll(".animation-fly-in");
    flyInElements.forEach((el) => animationObserver.observe(el));
  }
});
